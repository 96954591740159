import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faGithub,
  faFacebookF,
  faTwitter,
  faGoogle,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons'

import {
  faCircle
} from '@fortawesome/free-solid-svg-icons'

export default library.add(
  faGithub,
  faFacebookF,
  faTwitter,
  faGoogle,
  faLinkedinIn,
  faCircle
)